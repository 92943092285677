import { Trash, Delete } from "lucide-react";
function Chips({
  items,
  onDeleteItem,
}: {
  items: string[];
  onDeleteItem: (itemKey: string) => void;
}) {
  return (
    <div className="flex gap-2 py-2 w-max items-center overflow-auto">
      {items.length > 0 ? (
        items.map((item) => {
          return (
            <div
              key={item}
              className="flex gap-2 border border-gray-400 rounded-md w-max p-2 items-center"
            >
              <div className="text-xs">{item}</div>
              <button onClick={() => onDeleteItem(item)}>
                <Trash size={"15px"} />
              </button>
            </div>
          );
        })
      ) : (
        <div className="text-slate-700 text-sm dark:text-slate-300 mx-auto">
          Please add brokers
        </div>
      )}
    </div>
  );
}

export default Chips;
