import type {
  BrokerMetricsMetaData,
  BrokerMetricsObjects,
  GraphInputs,
} from "@/types/main";
import { fetchSlice, fetchBrokerData } from "../utils/server_talk";
import { useEffect, useState } from "react";
import React from "react";
import Chips from "./Chips";
import { format } from "date-fns";
import {
  BEAUTIFUL_COLORS,
  CriteriaMap,
  ON_PAN_BUCKETS_TO_FETCH,
} from "@/constants/broker_metrics";
import { convertToMap } from "@/utils/convert";
import PlotlyBox from "./PlotlyBox";
import LabelWrapper from "./LabelWrapper";
import MultiSelect from "./MultiSelect";

function BrokerMetrics({ brokers, end_points }: BrokerMetricsMetaData) {
  const [brokerMetricsMetaData, setBrokerMetricsMetaData] =
    useState<GraphInputs>({
      end_point: end_points[0],
      current_date: new Date().toISOString(),
    });

  const [brokerMetricsMap, setBrokerMetricsMap] = useState<
    Record<string, BrokerMetricsObjects>
  >({});
  const [edges, setEdges] = useState<{ left?: string; right?: string }>({});
  const [goDate, setGoDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [selectedBrokers, setSelectedBrokers] = useState<string[]>([]);

  const notAddedBrokers = brokers.filter(
    (broker) => !Object.keys(brokerMetricsMap).includes(broker)
  );
  useEffect(() => {
    if (brokers.length === 0 || end_points.length === 0) {
      return;
    }
    async function setInitialValues() {
      const [year, month, date] = goDate.split("-");
      const endDate = new Date();
      endDate.setDate(+date);
      endDate.setMonth(+month - 1);
      endDate.setFullYear(+year);
      // should be ist > 3:30 on that day
      endDate.setUTCHours(23, 59);
      const metrics = await fetchSlice(
        "desc",
        endDate.toISOString(),
        brokers[0],
        end_points[0],
        ON_PAN_BUCKETS_TO_FETCH
      );

      if (metrics.length > 0) {
        setEdges({
          right: metrics[0].end_time,
          left: metrics[metrics.length - 1].end_time,
        });
        setBrokerMetricsMap({
          [brokers[0]]: {
            metrics: convertToMap(metrics, {}),
            color: BEAUTIFUL_COLORS[0],
          },
        });
      } else {
        alert(`Not data present around ${goDate}`);
        return;
      }
    }
    setInitialValues();
  }, [brokers, end_points, goDate]);

  async function addBroker(broker: string) {
    if (!edges.right || !edges.left || !broker) {
      return;
    }
    const fetchedData = await fetchBrokerData(
      edges.left,
      edges.right,
      broker,
      brokerMetricsMetaData.end_point,
      Object.keys(brokerMetricsMap)[0]
        ? Math.max(
            ON_PAN_BUCKETS_TO_FETCH,
            Object.keys(
              brokerMetricsMap[Object.keys(brokerMetricsMap)[0]].metrics
            ).length
          )
        : ON_PAN_BUCKETS_TO_FETCH
    );
    const colors = Object.values(brokerMetricsMap).map(
      (broker) => broker.color
    );
    console.log("setting ", broker);
    // debugger;
    setBrokerMetricsMap((prevBrokerMap) => {
      return {
        ...prevBrokerMap,
        [broker]: {
          color:
            BEAUTIFUL_COLORS[
              (BEAUTIFUL_COLORS.indexOf(colors.pop() as string) + 1) %
                BEAUTIFUL_COLORS.length
            ],
          metrics: convertToMap(fetchedData, {}),
        },
      };
    });
  }

  return (
    <div className="mt-5">
      <div className="flex flex-col gap-2">
        <h1 className="font-bold md:text-3xl mb-10">
          Broker Speedtest - Compare Broker Performance
        </h1>
        <div className="flex gap-4 flex-col">
          <div className="flex gap-6 items-end flex-wrap">
            <LabelWrapper label="Select Criteria">
              <select
                className="p-1.5 bg-transparent border-[0.2px] border-gray-600/80 rounded-sm w-[8rem]"
                value={brokerMetricsMetaData.end_point}
                onChange={(e) => {
                  setBrokerMetricsMetaData({
                    ...brokerMetricsMetaData,
                    end_point: e.target.value,
                  });
                  setBrokerMetricsMap({});
                }}
              >
                {end_points.map((end_point) => {
                  return (
                    <option value={end_point} key={end_point}>
                      {CriteriaMap[end_point]}
                    </option>
                  );
                })}
              </select>
            </LabelWrapper>
            <LabelWrapper label="Select Brokers to Compare">
              <MultiSelect
                brokers={notAddedBrokers}
                placeHolder="Select brokers"
                selectedBrokers={selectedBrokers}
                toggleBroker={(broker: string) => {
                  if (selectedBrokers.includes(broker)) {
                    setSelectedBrokers(
                      selectedBrokers.filter((sBroker) => sBroker !== broker)
                    );
                  } else {
                    setSelectedBrokers([...selectedBrokers, broker]);
                  }
                }}
                onSelectAll={() => {
                  if (selectedBrokers.length === notAddedBrokers.length) {
                    setSelectedBrokers([]);
                  } else {
                    setSelectedBrokers([...notAddedBrokers]);
                  }
                }}
              />
            </LabelWrapper>
            <LabelWrapper label="Date">
              <input
                className="p-1 bg-transparent border-[0.2px] border-gray-600/80 rounded-sm"
                type="date"
                name="party"
                value={goDate}
                onChange={(e) => setGoDate(`${e.target.value}`)}
              />
            </LabelWrapper>
            <button
              disabled={selectedBrokers.length === 0}
              className="bg-[#399BE1] dark:disabled:bg-[#399BE1]/40 text-white disabled:text-gray-300 !py-1.5 px-4 rounded w-max"
              onClick={async () => {
                for (let broker of selectedBrokers) {
                  await addBroker(broker);
                  setSelectedBrokers((prevSelectedBrokers) => {
                    return prevSelectedBrokers.filter(
                      (includedBroker) => includedBroker !== broker
                    );
                  });
                }
              }}
            >
              Visualize
            </button>
          </div>
          <Chips
            items={Object.keys(brokerMetricsMap)}
            onDeleteItem={(item) => {
              let state = { ...brokerMetricsMap };
              delete state[item];
              setBrokerMetricsMap(state);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="min-h-[30rem]">
          <PlotlyBox data={brokerMetricsMap} />
        </div>
        <div className=" dark:text-slate-400 text-gray-600 italic">
          The graph above shows the API response times for different brokers.
          This visual comparison allows you to quickly identify which brokers
          provide the fastest and most reliable service, crucial for executing
          timely trades. AlgoTest is dedicated to supporting the Indian trading
          community with transparent and actionable insights.
        </div>
      </div>
    </div>
  );
}

export default BrokerMetrics;
